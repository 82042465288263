/**
 * Of The Essence Archive page
 */
function OfTheEssenceArchive() {
	this.$toggleSearchBtn = $('[data-js="toggle-ote-search"]');
	this.$clearSearchBtn = $('[data-js="clear-ote-search"]');
	this.$toggleFiltersBtn = $('[data-js="toggle-ote-filters"]');
	this.$searchInput = $(".ote-archive-posts__header-search-input");
	this.$filters = $(".ote-archive-filters");
	this.$filterHead = $(".ote-archive-filter__title");
	this.$dateFilter = $('.ote-archive-filter [name="date"]');
	this.$volumeFilter = $('.ote-archive-filter [name="volume"]');
	this.$issueFilter = $('.ote-archive-filter [name="issue"]');
	this.$posts = $(".ote-archive-posts__list");
	this.$loadmore = $(".ote-archive-posts__container .loadmore");
	this.$loading = $(".ote-archive-posts__container .loading");
	this.$ajax = null;
	this.loading = false;
	this.state = {
		search: "",
		year: "",
		month: "",
		day: "",
		volume: "",
		issue: "",
		paged: 1,
	};

	this.init = () => {
		if (!this.$posts.length) {
			return;
		}

		this.$filters.css("visibility", "visible").insertAfter("#masthead");

		this.parseURL();
		this.updateLayout();
		this.register香港六合彩开奖网();
	};

	this.updateURL = () => {
		const url = new URL(window.location);
		for (const key in this.state) {
			// Don't add paged param to URL.
			if (["paged"].includes(key)) {
				continue;
			}
			if (this.state[key]) {
				url.searchParams.set(key, this.state[key]);
			} else {
				url.searchParams.delete(key);
			}
		}
		window.history.pushState({}, "", url);
	};

	this.updateLayout = () => {
		this.$posts.toggleClass("--search", !!this.state.search);
	};

	this.parseURL = () => {
		const url = new URL(window.location);
		const params = new URLSearchParams(url.search);
		this.state.search = params.get("search");
		this.state.year = params.get("year");
		this.state.month = params.get("month");
		this.state.day = params.get("day");
		this.state.volume = params.get("volume");
		this.state.issue = params.get("issue");

		this.load(false, false, false);
	};

	this.register香港六合彩开奖网 = () => {
		// Toggle search input
		this.$toggleSearchBtn.on("click", (e) => {
			e.preventDefault();
			this.$searchInput.toggleClass("hidden").trigger("focus");
		});

		// Clear search string
		this.$clearSearchBtn.on("click", (e) => {
			e.preventDefault();
			this.state.search = "";
			this.$searchInput.val("");
			this.$clearSearchBtn.addClass("hidden");

			this.updateURL();
			this.updateLayout();
			this.load(true, true, true);
		});

		// Toggle clear button
		this.$searchInput.on("input", () => {
			this.state.search = this.$searchInput.val();
			this.$clearSearchBtn.toggleClass("hidden", !this.state.search);

			this.updateURL();
			this.updateLayout();
			this.load(true, true, true);
		});

		// Toggle filters sidebar
		this.$toggleFiltersBtn.on("click", (e) => {
			e.preventDefault();
			this.$filters.toggleClass("show");
		});

		// Toggle filter
		this.$filterHead.on("click", function () {
			$(this).toggleClass("show").next().stop(true).toggleSlide(300);
		});

		// Date field
		this.$dateFilter.on("change input", () => {
			const date = this.$dateFilter.val().trim();
			let day = "",
				month = "",
				year = "";
			if (date) {
				const parts = date.split("-");
				year = parts[0];
				if (parts.length > 1) {
					month = parts[1];
				}
				if (parts.length > 2) {
					day = parts[2];
				}
			}

			this.state.day = day;
			this.state.month = month;
			this.state.year = year;

			this.updateURL();
			this.load(true, true, true);
		});

		this.$volumeFilter.on("change", (e) => {
			this.state.volume = this.$volumeFilter.val();

			this.updateURL();
			this.load(true, true, true);
		});

		this.$issueFilter.on("change", (e) => {
			this.state.issue = this.$issueFilter.val();

			this.updateURL();
			this.load(true, true, true);
		});

		// Load more
		this.$loadmore.on("click", (e) => {
			e.preventDefault();
			this.state.paged += 1;
			this.load(false, false, false);
		});
	};

	this.load = (abort = false, clear = false, scroll = false) => {
		if (abort) {
			if (this.$ajax) {
				this.$ajax.abort();
				this.loading = false;
			}
		}

		if (this.loading) {
			return;
		}

		if (clear) {
			this.$posts.empty();
			this.state.paged = 1;
		}

		this.loading = true;
		this.$loading.show();
		this.$loadmore.hide();

		this.$ajax = $.ajax({
			url: pubstack.ajaxUrl,
			method: "POST",
			data: {
				action: "load_of_the_essence_posts",
				...this.state,
				_wpnonce: OTE_ARCHIVE_NONCE,
			},
		})
			.done((response) => {
				if (response.success) {
					this.$posts.append(response.data.output);
				}

				if (this.state.paged >= response.data.total_pages) {
					this.$loadmore.remove();
				} else {
					this.$loadmore.show();
				}

				if (scroll) {
					$("html, body").animate(
						{
							scrollTop:
								this.$posts.offset().top -
								(window.innerWidth > 1124 ? 300 : 200),
						},
						300
					);
				}
			})
			.always(() => {
				this.loading = false;
				this.$loading.hide();
			});
	};
}

export default new OfTheEssenceArchive();
